.choose-shop__head {
    display: flex;
    margin: 0 16px 10px;
    align-items: center;
}

@media (min-width: 480px) {
    .choose-shop__head {
        margin: 0 20px 10px;
    }
}

.choose-shop__head__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: var(--text);
}

@media (min-width: 480px) {
    .choose-shop__head__title {
        font-size: 27px;
        line-height: 34px;
    }
}

.choose-shop__head__close-icon {
    width: 24px;
    height: 24px;
    background-color: var(--text);
    mask-image: url(/public/img/close.svg);
    mask-repeat: no-repeat;
    cursor: pointer;
    margin-left: auto;
}

@media (min-width: 480px) {
    .choose-shop__head__close-icon {
        width: 34px;
        height: 34px;
    }
}

.choose-shop__description {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 0 16px 16px;
    color: var(--text);
}

@media (min-width: 480px) {
    .choose-shop__description {
        font-size: 20px;
        line-height: 25px;
        margin: 0 20px 40px;
    }
}

.choose-shop__search {
    display: flex;
    align-items: center;
    margin: 0 16px 16px;
}

@media (min-width: 480px) {
    .choose-shop__search {
        margin: 0 20px 40px;
    }
}

.chooseShop__SearchInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}

.chooseShop__searchIcon {
    margin-left: 8px;
    width: 40px;
    height: 40px;
}

@media (max-width: 480px) {
    .chooseShop__title {
        color: var(--text);
        font-weight: 700;
        font-size: 20px;
        line-height: 34px;
        flex: none;
        order: 0;
        flex-grow: 0;
        width: 260px;
        height: 28px;
        margin-left: 10px;
    }

    .chooseShop__description {
        display: flex;
        margin-left: 10px;
        font-weight: 200;
        font-size: 16px;
    }
}

.shop-loader {
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    color: var(--text);
}