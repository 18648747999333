.products {
    display: flex;
    flex-wrap: wrap;
    margin: 0 16px;
}

@media (min-width: 480px) {
    .products {
        margin: 0 20px;
    }
}

.products-loader {
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    color: var(--text);
    width: 100%;
}