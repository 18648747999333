.product {
    width: 50%;
    padding: 16px 14px;
}

@media (min-width: 480px) {
    .product {
        padding: 40px 24px;
    }
}

.product__content {
    width: 100%;
    height: fit-content;
    position: relative;
    margin-bottom: 8px;
}

.product__current-price {
    margin-bottom: 2px;
    color: var(--text);
    font-weight: 700;
}

.product__old-price {
    margin-bottom: 8px;
    color: grey;
    font-size: 14px;
    text-decoration: line-through;
}

.product__image {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    cursor: pointer;
}

.product__description {
    font-size: 14px;
    line-height: 18px;
}

@media (min-width: 480px) {
    .product__description {
        font-size: 16px;
        line-height: 20px;
    }
}

.product__priceSm {
    font-size: 15px;
    line-height: 18px;
}

.bg-white {
    background-color: var(--white);
}

.bg-red {
    background-color: var(--main-accent);
}

.product__amount {
    color: var(--white);
    font-weight: 700;
    font-size: 16px;
}

.product__discount {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 0;
    color: white;
    background-color: var(--main-accent);
    border-bottom-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 4px 14px;
    font-weight: 700;
    cursor: pointer;
}

.product__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
}

.product__control {
    display: flex;
    gap: 3px
}

.product__shop-button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product__shop-icon {
    width: 20px;
    height: 20px;
    background-color: var(--main-accent);
    mask-image: url(/public/img/add-shop.svg);
    mask-repeat: no-repeat;
}

.product__minus-icon {
    width: 14px;
    height: 2px;
    background-color: var(--main-accent);
    mask-image: url(/public/img/minus.svg);
    mask-repeat: no-repeat;
}

.product__plus-icon {
    width: 14px;
    height: 14px;
    background-color: var(--main-accent);
    mask-image: url(/public/img/plus.svg);
    mask-repeat: no-repeat;
}

.product__clear {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #BEBEBE;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
}

.product__clear-icon {
    width: 18px;
    height: 18px;
    background-color: white;
    mask-image: url(/public/img/close.svg);
    mask-repeat: no-repeat;
}