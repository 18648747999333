.reset-basket-modal__head {
    display: flex;
    gap: 10px;
}


.reset-basket-modal__close-icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
    background-color: var(--text);
    mask-image: url(/public/img/close.svg);
    mask-repeat: no-repeat;
    cursor: pointer;
}

.reset-basket-modal__text {
    font-family: 'Geometria', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: var(--text);
    white-space: break-spaces;
}

.reset-basket-modal__gray-line {
    width: 100%;
    margin: 20px 0;
    border-top: 1px solid #E8E8E8;
}

.reset-basket-modal__buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 20px;
}

.reset-basket-modal__button {
    width: 80px;
    box-shadow: 0 7px 20px rgba(0, 0, 0, 0.15);
    border-radius: 34px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    text-align: center;
    padding: 10px 0;
}

.reset-basket-modal__button-red {
    background-color: var(--main-accent);
    color: white
}

.reset-basket-modal__button-white {
    background-color: white;
    color: var(--text)
}