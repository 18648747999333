.shop-item {
    display: flex;
    align-items: center;
    max-width: 511px;
    margin: 40px 16px 0;
    cursor: default;
}

@media (min-width: 480px) {
    .shop-item {
        margin: 40px 20px 0;
    }
}

.shop-item__content {
    width: 100%;
}

.shop-item__text {
    font-family: 'Geometria-Narrow', sans-serif;
    font-size: 16px;
    line-height: 20px;
}

@media (min-width: 480px) {
    .shop-item__text {
        font-size: 20px;
        line-height: 24px;
    }
}

.shop-item__logo {
    width: 56px;
    max-width: 56px;
    margin-right: 16px
}

.shop-item__address {
    margin-bottom: 4px;
    color: var(--text);
}

.shop-item__bottom {
    display: flex;
    align-items: center;
}

.shop-item__clock-icon {
    width: 16px;
    height: 16px;
    background-color: #8E8E8E;
    mask-image: url(/public/img/clock.svg);
    mask-repeat: no-repeat;
    margin-right: 6px;
}

.shop-item__clock-text {
    margin-right: auto;
    color: #8E8E8E;
}

.shop-item__button {
    padding: 6px 20px;
    cursor: pointer;
    border: 1px solid var(--main-accent);
    border-radius: 30px;
    color: var(--main-accent);
}