.filter__wrapper {
    width: 100%;
}

.filter__head {
    width: 100%;
    display: flex;
    padding: 0 10px 20px 10px;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: var(--text);
    align-items: center;
    font-family: "Geometria", sans-serif;
}

@media (min-width: 480px) {
    .filter__head {
        width: 100%;
        padding: 20px 10px 0;
        font-size: 27px;
        line-height: 34px;
    }
}

.filter__close-icon {
    width: 24px;
    height: 24px;
    background-color: var(--text);
    mask-image: url(/public/img/close.svg);
    mask-repeat: no-repeat;
    cursor: pointer;
    margin-left: auto;
}

@media (min-width: 480px) {
    .filter__close-icon {
        width: 34px;
        height: 34px;
    }
}

.filter__gray-line {
    width: 100%;
    border-top: 1px solid #E8E8E8;
}

.filter__element {
    padding: 20px 10px;
    position: relative;
}

@media (min-width: 480px) {
    .filter__element {
        padding: 40px 24px;
    }
}

.filter__element-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--text);
    margin-bottom: 24px;
    font-family: "Geometria", sans-serif;
}

@media (min-width: 480px) {
    .filter__element-title {
        font-size: 20px;
        line-height: 25px;
    }
}

.filter__submit {
    position: relative;
    margin: 40px 20px 20px;;
}

@media (min-width: 480px) {
    .filter__submit {
        margin: 60px 20px 20px;
    }
}

.filter__submit-button {
    padding: 10px 16px;
    background-color: var(--main-accent);
    border-radius: 30px;
    font-size: 16px;
    line-height: 20px;
    color: var(--white);
    text-transform: uppercase;
    cursor: pointer;
    width: fit-content;
    margin: 0 auto;
}

@media (min-width: 480px) {
    .filter__submit-button {
        padding: 16px 24px;
        font-size: 20px;
        line-height: 25px;
    }
}

.filter__trash-icon {
    width: 24px;
    height: 24px;
    background-color: var(--main-accent);
    mask-image: url(/public/img/trash-2.svg);
    mask-repeat: no-repeat;
    cursor: pointer;
    right: 0;
    top: calc(50% - 12px);
    position: absolute;
}

@media (min-width: 480px) {
    .filter__trash-icon {
        top: calc(50% - 17px);
        width: 34px;
        height: 34px;
    }
}

.filter__element-sort {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter__element-sort-item {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.filter__element-sort-icon {
    width: 24px;
    height: 24px;
    background-color: var(--main-accent);
    mask-image: url(/public/img/min-max.svg);
    mask-repeat: no-repeat;
    margin-right: 12px;
}

@media (min-width: 480px) {
    .filter__element-sort-icon {
        margin-right: 20px;
    }
}

.filter__element-sort-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--text);
}

.filter__rotate {
    transform: scale(1, -1);;
}

.filter__bold {
    font-weight: 700;;
}

/*.sort-wrapper__item-text {*/
/*    font-family: 'Geometria-Narrow', sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    font-size: 18px;*/
/*    line-height: 24px;*/
/*    margin-left: 12px;*/
/*}*/

/*@media (min-width: 480px) {*/
/*    .sort-wrapper__item-text {*/
/*        margin-left: 22px;*/
/*    }*/
/*}*/

/*.productDisplaySetting {*/
/*    min-height: 350px;*/
/*}*/

/*.subtitle__filter {*/
/*    font-family: 'Geometria-Narrow', sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 700;*/
/*    font-size: 16px;*/
/*    line-height: 20px;*/
/*    padding: 25px;*/
/*    margin-bottom: 50px;*/
/*    user-select: none;*/
/*}*/

/*@media (min-width: 480px) {*/
/*    .subtitle__filter {*/
/*        font-size: 20px;*/
/*        line-height: 25px;*/
/*    }*/
/*}*/

/*.subtitle__filter-sort {*/
/*    font-family: 'Geometria-Narrow', sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 700;*/
/*    font-size: 16px;*/
/*    line-height: 20px;*/
/*    padding: 25px;*/
/*}*/

/*@media (min-width: 480px) {*/
/*    .subtitle__filter-sort {*/
/*        font-size: 20px;*/
/*        line-height: 25px;*/
/*    }*/
/*}*/

/*.sort-wrapper {*/
/*    width: 100%;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: space-between;*/
/*    padding: 0 20px;*/
/*    user-select: none;*/
/*}*/

/*.sort-wrapper__item {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    cursor: pointer;*/
/*}*/

/*.sort-wrapper__item-text {*/
/*    font-family: 'Geometria-Narrow', sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    font-size: 18px;*/
/*    line-height: 24px;*/
/*    margin-left: 12px;*/
/*}*/

/*@media (min-width: 480px) {*/
/*    .sort-wrapper__item-text {*/
/*        margin-left: 22px;*/
/*    }*/
/*}*/


/*.sort-wrapper__item-image {*/

/*}*/

/*@media (max-width: 480px) {*/
/*    .subtitle__filter {*/
/*        font-family: 'Geometria-Narrow', sans-serif;*/
/*        font-style: normal;*/
/*        font-weight: 700;*/
/*        font-size: 18px;*/
/*        line-height: 25px;*/
/*        padding: 25px;*/
/*        margin-bottom: 50px;*/
/*    }*/
/*}*/

/*@media (max-width: 440px) {*/
/*    .subtitle__filter {*/
/*        font-family: 'Geometria-Narrow', sans-serif;*/
/*        font-style: normal;*/
/*        font-weight: 700;*/
/*        font-size: 16px;*/
/*        line-height: 25px;*/
/*        padding: 25px;*/
/*        margin-bottom: 50px;*/
/*    }*/
/*}*/

/*@media (max-width: 400px) {*/
/*    .subtitle__filter {*/
/*        font-family: 'Geometria-Narrow', sans-serif;*/
/*        font-style: normal;*/
/*        font-weight: 700;*/
/*        font-size: 14px;*/
/*        line-height: 25px;*/
/*        padding: 25px;*/
/*    }*/
/*}*/

/*@media (max-width: 360px) {*/
/*    .subtitle__filter {*/
/*        font-family: 'Geometria-Narrow', sans-serif;*/
/*        font-style: normal;*/
/*        font-weight: 700;*/
/*        font-size: 12px;*/
/*        line-height: 25px;*/
/*        margin-bottom: 50px;*/
/*        padding-left: 50px;*/
/*        padding-bottom: 20px;*/
/*    }*/
/*}*/

/*@media (max-width: 340px) {*/
/*    .subtitle__filter {*/
/*        font-family: 'Geometria-Narrow', sans-serif;*/
/*        font-style: normal;*/
/*        font-weight: 700;*/
/*        font-size: 12px;*/
/*        line-height: 25px;*/
/*        margin-bottom: 20px;*/
/*        padding-left: 100px;*/
/*    }*/
/*}*/

/*.sort-bold {*/
/*    font-weight: bold;*/
/*}*/