.wrapperMain {
    width: 100vw;
    max-height: calc(100vh - 68px);
    overflow: auto;
}

@media (min-width: 480px) {
    .wrapperMain {
        height: calc(100vh - 96px);
    }
}

.wrapperMain::-webkit-scrollbar {
    display: none;
}

.wrapperContent {
    background-color: var(--white);
    border-radius: 20px;
    padding: 16px 0;
    overflow: hidden;
    margin-bottom: 100px;
}

@media (min-width: 480px) {
    .wrapperContent {
        border-radius: 40px;
        padding: 20px 0;
    }
}

.no-padding {
    padding: 0;
    margin-bottom: 0;
}