.header-basket {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    gap: 15px;
    background-color: #F6F6F6;
}

@media (min-width: 480px) {
    .header-basket {
        margin: 0 20px;
    }
}

.header-basket__shop-logo {
    min-width: 56px;
    width: 56px;
    height: 56px;
}

.header-basket__remove-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 46px;
    min-width: 46px;
    width: 46px;
    border: 1px solid #D1D1D1;
    border-radius: 50%;
}

.header-basket__close-icon {
    width: 24px;
    height: 24px;
    background-color: #D1D1D1;
    mask-image: url(/public/img/close.svg);
    mask-repeat: no-repeat;
}

.header-basket__work-time {
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #8E8E8E;
    text-align: center;
}

.header-basket__shop-name {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: var(--text);
    width: 100%;
}

.header-basket__clock-icon {
    width: 16px;
    height: 16px;
    background-color: #8E8E8E;
    mask-image: url(/public/img/clock.svg);
    mask-repeat: no-repeat;
}

.header-basket__content {
    width: 100%;
}

/*.headerBasket__info {*/
/*    display: flex;*/
/*    gap: 16px;*/
/*    cursor: pointer;*/
/*}*/

/*.headerBasket__infoLogo {*/
/*    width: 56px;*/
/*}*/

/*.headerBasket__trash-icon {*/
/*    width: 24px;*/
/*    height: 24px;*/
/*    min-width: 24px;*/
/*    background-color: var(--main-accent);*/
/*    mask-image: url(/public/img/trash-2.svg);*/
/*    mask-repeat: no-repeat;*/
/*    cursor: pointer;*/
/*    margin-left: 16px;*/
/*}*/

/*.headerBasket__infoTitle {*/
/*    font-weight: 500;*/
/*    font-size: 17px;*/
/*    line-height: 21px;*/
/*    margin-bottom: 6px;*/
/*    color: var(--text);*/
/*}*/

/*@media (min-width: 480px) {*/
/*    .headerBasket__infoTitle {*/
/*        font-size: 22px;*/
/*        line-height: 28px;*/
/*    }*/
/*}*/

/*.headerBasket__infoWork {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: space-between;*/
/*}*/

/*.headerBasket__infoTime {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    gap: 10px;*/
/*    font-size: 16px;*/
/*    line-height: 20px;*/
/*    color: #8E8E8E;*/
/*}*/


/*.headerBasket__title {*/
/*    font-weight: 500;*/
/*    font-size: 22px;*/
/*    color: var(--text)*/
/*}*/

/*.headerBasket__delete {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    font-weight: 900;*/
/*    font-size: 22px;*/
/*    line-height: 25px;*/
/*    padding: 10px 20px;*/
/*    width: 100vw;*/
/*    height: 7vh;*/
/*    border: 1px solid #DBDBDB;*/
/*    border-radius: 30px;*/
/*    color: #c0c0c0;*/
/*}*/