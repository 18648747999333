.terms {
    width: 100vw;
    height: 100vh;
    background-image: url(/public/img/bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: auto;
}

.terms::-webkit-scrollbar {
    display: none;
}

.link {
    font-family: 'Geometria', sans-serif;
    display: initial;
    font-weight: 700;
    text-decoration: underline;
}

.terms__wrapper {
    width: 100vw;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.terms__back {
    font-weight: 900;
    font-size: 29px;
    color: white;
    cursor: pointer;
    margin-right: auto;
}

.terms__content {
    padding: 30px 20px;
    border: 10px solid rgba(255, 255, 255, 0.5);
    border-radius: 30px;
    width: 100%;
    background-color: var(--white);
    background-clip: padding-box;
}

.terms__title {
    font-family: 'Geometria', sans-serif;
    font-size: 30px;
    margin-bottom: 30px;
    color: black;
    font-weight: 700;
}

.terms__subtitle {
    font-family: 'Geometria', sans-serif;
    font-size: 23px;
    margin-bottom: 30px;
    margin-top: 20px;
    color: black;
    font-weight: 700;
}

.terms__game-description {
    font-family: 'Geometria', sans-serif;
    font-size: 17px;
    margin-bottom: 30px;
    color: red;
    font-weight: 700;
}

.terms__item {
    display: flex;
    margin-bottom: 30px;
}

.terms__item-title {
    font-family: 'Geometria', sans-serif;
    font-size: 14px;
    color: black;
    font-weight: 700;
}

.terms__item-text {
    font-family: 'Geometria', sans-serif;
    font-size: 12px;
    color: black;
    white-space: break-spaces;
}

.terms__info-icon {
    mask-image: url(/public/img/info.svg);
    min-width: 22px;
    height: 22px;
    margin-right: 15px;
    mask-repeat: no-repeat;
    background-color: red;
}

.terms__selected-icon {
    mask-image: url(/public/img/selected.svg);
    min-width: 22px;
    height: 22px;
    margin-right: 15px;
    mask-repeat: no-repeat;
    background-color: #05BF4F;
}

.terms__ellipse {
    height: 22px;
    min-width: 22px;
    width: 10px;
    border-radius: 50%;
    background-color: #003EB3;
    padding: 6px;
    margin-right: 15px;
    background-clip: content-box;
    margin-top: -3px;
}

.no-mb {
    margin-bottom: 0;
}