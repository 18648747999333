.main-menu {
    width: 100vw;
    height: 100vh;
    background-image: url(/public/img/bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: auto;
    padding-bottom: 120px;
}

.main-menu::-webkit-scrollbar {
    display: none;
}

.main-menu__wrapper {
    width: 100vw;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.main-menu__magnit-icon {
    width: 68px;
    min-height: 68px;
    margin-bottom: 34px;
    mask-image: url(/public/img/magnit-logo.svg);
    background-color: white;
    mask-repeat: no-repeat;
}

.main-menu__magnit-icon-anim {
    animation: magnitIcon 3s ease-in;
}

.main-menu__content {
    width: 280px;
    padding-top: 104px;
    border-radius: 14px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.main-menu__bell {
    position: absolute;
    top: 0;
    width: 150px;
    height: 80px;
    left: calc(50% - 75px);
}

.main-menu__bell-anim {
    opacity: 0;
    animation: flipInX 0.4s 7.6s forwards ease-in;
}

.main-menu__button {
    width: 100%;
    height: 50px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    cursor: pointer;
    position: relative;
    box-shadow: 0 4px 4px rgba(174, 203, 234, 0.5);
    border-radius: 5px;
    gap: 20px;
}

.main-menu__button-anim {
    animation: fadeInDown 0.3s forwards ease-in;
    opacity: 0;
}

.main-menu__text {
    font-size: 14px;
    font-weight: 600;
    color: #191D3F;
    text-transform: uppercase;
}

.main-menu__icon {
    min-width: 20px;
    height: 20px;
    background-color: var(--main-accent);
    mask-repeat: no-repeat;
}

.main-menu__bottom-icon {
    width: 22px;
    height: 22px;
    background-color: white;
    mask-repeat: no-repeat;
}

.main-menu__bottom {
    position: fixed;
    background-color: white;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 120px;
    padding: 16px 30px;
    justify-content: space-between;
    display: flex;
}

.main-menu__bottom-anim {
    animation: slideInUp 0.4s forwards 4.8s linear;
    opacity: 0;
}

.main-menu__bottom-el {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.advertisement {
    opacity: 0;
    animation: opacityIn 0.2s 5.5s forwards ease-in;
}

.main-menu__bottom-text {
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    white-space: pre-wrap;
}

.main-menu__bottom-text-anim {
    opacity: 0;
    animation: opacityIn 0.4s 7s forwards ease-in;
}

.main-menu__bottom-button {
    width: 55px;
    height: 55px;
    cursor: pointer;
    border-radius: 100%;
    background-color: var(--main-accent);
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-menu__bottom-button-anim {
    opacity: 0;
    animation: bounceIn 0.4s forwards ease-in;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -20px, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: scale3d(0, 0, 0);
    }

    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1);
    }

    80% {
        transform: scale3d(0.9, 0.9, 0.9);
    }

    to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

@keyframes slideInUp {
    from {
        transform: translate3d(0, 100%, 0);
        opacity: 1;
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes opacityIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes flipInX {
    from {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        animation-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    60% {
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }

    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        opacity: 1;
    }

    to {
        transform: perspective(400px);
        opacity: 1;
    }
}

@keyframes magnitIcon {
    from {
        transform: perspective(400px) translate3d(0, 330%, 0) scale3d(2, 2, 2) rotate3d(1, 0, 0, 180deg) translatez(50px);
        transform-style: preserve-3d;
    }

    20% {
        transform: perspective(400px) translate3d(0, 330%, 0) scale3d(2, 2, 2) rotate3d(1, 0, 0, -20deg) translatez(50px);
        transform-style: preserve-3d;
    }

    26% {
        transform: perspective(400px) translate3d(0, 330%, 0) scale3d(2, 2, 2) rotate3d(1, 0, 0, -4deg) translatez(50px);
        transform-style: preserve-3d;
    }

    28% {
        transform: perspective(400px) translate3d(0, 330%, 0) scale3d(2, 2, 2) rotate3d(1, 0, 0, 0deg) translatez(50px);
        transform-style: preserve-3d;
    }

    40% {
        transform: perspective(400px) translate3d(0, 330%, 0) scale3d(2, 2, 2) rotate3d(1, 0, 0, 0deg) translatez(50px);
        transform-style: preserve-3d;
    }

    100% {
        transform: perspective(400px) translate3d(0, 0, 0) scale3d(1, 1, 1) rotate3d(1, 0, 0, 0deg) translatez(50px);
        transform-style: preserve-3d;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}


.main-menu__check-icon {
    mask-image: url(/public/img/tg-full.svg);
}

.main-menu__info-icon {
    mask-image: url(/public/img/tag.svg);
    -webkit-mask-position: center;
    height: 28px;
    width: 26px;
}

.main-menu__human-icon {
    mask-image: url(/public/img/human-full.svg);
}

.main-menu__phone-icon {
    mask-image: url(/public/img/magnit-logo.svg);
    height: 26px;
    width: 26px;
}

.main-menu__dish-icon {
    mask-image: url(/public/img/dish.svg);
}

.main-menu__bonus-icon {
    mask-image: url(/public/img/bonus.svg);
}

.main-menu__coupon-icon {
    mask-image: url(/public/img/coupon.svg);
}

.main-menu__question-icon {
    mask-image: url(/public/img/question.svg);
}

.main-menu__share-icon {
    mask-image: url(/public/img/share.svg);
}

.main-menu__refresh-icon {
    mask-image: url(/public/img/refresh.svg);
}

.main-menu__star-icon {
    mask-image: url(/public/img/star.svg);
    mask-repeat: no-repeat;
}

.main-menu__circle-icon {
    border-radius: 100%;
}

.main-menu__snow {
    background-color: white;
    position: fixed;
    width: 10px;
    aspect-ratio: 1;
    opacity: 0;
}

.zoom-in-1 {
    animation: zoomIn1 ease-in forwards;
}

.zoom-in-2 {
    animation: zoomIn2 ease-in forwards;
}

.zoom-in-3 {
    animation: zoomIn3 ease-in forwards;
}

.zoom-in-4 {
    animation: zoomIn4 ease-in forwards;
}

@keyframes zoomIn1 {
    from {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    80% {
        transform: scale3d(1.2, 1.2, 1.2);
    }

    100% {
        transform: scale3d(1, 1, 1);
        opacity: 1;
    }
}

@keyframes zoomIn2 {
    from {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    80% {
        transform: scale3d(1.2, 1.2, 1.2);
        opacity: 1;
    }

    100% {
        transform: scale3d(1, 1, 1);
        opacity: 0.9;
    }
}

@keyframes zoomIn3 {
    from {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    80% {
        transform: scale3d(1.2, 1.2, 1.2);
        opacity: 1;
    }

    100% {
        transform: scale3d(1, 1, 1);
        opacity: 0.8;
    }
}

@keyframes zoomIn4 {
    from {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    80% {
        transform: scale3d(1.2, 1.2, 1.2);
        opacity: 1;
    }

    100% {
        transform: scale3d(1, 1, 1);
        opacity: 0.7;
    }
}

