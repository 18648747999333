.slider {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
    position: absolute;
}

.slider__track,
.slider__range {
    border-radius: 3px;
    height: 5px;
}

.slider__track {
    background-color: #ced4da;
    width: 100%;
    z-index: 1;

}

.slider__range {
    background-color: #ED1925;
    z-index: 2;
}

.slider__left-value,
.slider__right-value {
    color: var(--white);
    font-size: 14px;
    margin-top: -36px;
    width: 39px;
    height: 35px;
    font-weight: 700;
    line-height: 25px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    clip-path: polygon(69.47% 73.67%, 50.41% 98.89%, 31.06% 74.58%, 1.77% 72.20%, 0.00% 0.00%, 46.92% 0.00%, 100.00% 0.00%, 97.80% 72.10%);
    background-color: var(--text);
    border-radius: 5px;
    bottom: 16px;
}

/*.slider__left-value {*/
/*    left: 90%;*/
/*}*/

/*.slider__right-value {*/
/*    right: -4px;*/
/*}*/

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
}

.thumb--left {
    z-index: 3;
}

.thumb--right {
    z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
    background-color: #ED1925;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 23px;
    width: 23px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;

}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 23px;
    width: 23px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}

.multi-slider {
    margin: 70px 16px 20px;
    position: relative;
}

.titleDiscount {
    font-weight: 900;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    margin-bottom: 88px;
    margin-top: 40px;
}

.multi-slider__titleSale {
    margin-bottom: 40px;
}
